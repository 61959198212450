import client from "../api/axiosClient";

const apiCreateSite = (data) => {
  return client.post("/api/site/create", data);
};

const apiEditSite = (data) => {
  return client.put("/api/site/update", data);
};

const apiDeleteSite = (id) => {
  return client.delete(`/api/site/delete/${id}`);
};

const apiGetListSites = ({ pageIndex, pageSize, textSearch, field, order }) => {
  return client.get(
    `/api/site/list?currentPage=${pageIndex}&DisplayItem=${pageSize}&textSearch=${textSearch}&Field=${field}&Order=${order}`
  );
};

const apiGetListSitesEnable = ({ pageIndex, pageSize }) => {
  return client.get(
    `/api/site/list?currentPage=${pageIndex}&DisplayItem=${pageSize}&status=1`
  );
};

const apiDetailSite = (id) => {
  return client.get(`/api/site/detail/${id}`);
};

const apiSettingSite = (data) => {
  return client.put(`/api/site/updatesitesetting`, data);
};

const apiDetailSiteUser = (id) => {
  return client.get(`/api/site/detailSiteUser/${id}`);
};

const apiItemIdExistInReport = (data) => {
  return client.post("/api/site/check-id-master-data-exist-in-report", data);
};

const apiCheckRemoveAllItemOfMasterData = (data) => {
  return client.post(
    "/api/site/check-remove-all-master-data-exist-in-report",
    data
  );
};

const apiCheckLocationExistInReport = (data) => {
  return client.post("/api/site/check-location-data-exist-in-report", data);
};

const apiBackupSite = async (data) => {
  return client.post("/api/site/backup", data, {
    responseType: "blob",
  });
};

const apiImportSite = async (data) => {
  return client.post("/api/site/import", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export {
  apiCreateSite,
  apiEditSite,
  apiDeleteSite,
  apiGetListSites,
  apiDetailSite,
  apiGetListSitesEnable,
  apiSettingSite,
  apiDetailSiteUser,
  apiItemIdExistInReport,
  apiCheckRemoveAllItemOfMasterData,
  apiCheckLocationExistInReport,
  apiBackupSite,
  apiImportSite,
};
