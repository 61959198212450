import React, { useEffect, useState } from "react";
import "./style.scss";
import Button from "../../component/common/Button";
import TimesSheetBrand from "../../assets/images/timesheet_logo_1.png";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { apiLogin } from "../../services/Auth";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../Routes";
import CryptoJS from "crypto-js";
import { ToastContainer } from "react-toastify";
import { ToastFailed } from "../../component/common/Toast";
import { Role } from "../../utils/Role";
import { useDispatch } from "react-redux";
import { loginSuccess, updateUserData } from "../../store/actions/authAction";
import IconEyeShow from "../../assets/icons/icon-eye-show.png";
import IconEyeHidden from "../../assets/icons/icon-eye-hidden.png";
import { setSiteLocal } from "../../utils/auth";
import { updateSelectedSiteReducer } from "../../store/actions/siteAction";
const secretKey = process.env.REACT_APP_SECRET_KEY || "";

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [formInput, setFormInput] = useState({
    userId: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    userId: "",
    password: "",
  });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedUserId = localStorage.getItem("timesheet_userId");
    const storedPassword = localStorage.getItem("timesheet_password");
    if (storedPassword && storedUserId) {
      const bytes = CryptoJS.AES.decrypt(storedPassword, secretKey);
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

      const storedRememberMe = localStorage.getItem("timesheet_rememberMe");

      if (storedRememberMe && storedRememberMe === "true") {
        setFormInput({
          userId: storedUserId,
          password: decryptedText,
        });
        setRememberMe(true);
      }
    }
  }, []);

  const navigate = useNavigate();
  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleOnChange = (e, name) => {
    setFormInput({ ...formInput, [name]: e.target.value });
    setErrors({ ...errors, [name]: "" });
    if (
      name === "userId" &&
      errors.password === "ID or password are incorrect!"
    ) {
      setErrors({ userId: "", password: "" });
    }
  };

  const handleBlur = (name) => {
    setFormInput({ ...formInput, [name]: formInput[name]?.trim() });
  };

  const handleChecked = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const { userId, password } = formInput;
    const newErrors = {
      userId: formInput.userId.trim() ? "" : "Username is required field",
      password: formInput.password.trim() ? "" : "Password is required field",
    };

    setErrors(newErrors);

    if (!formInput.userId || !formInput.password) {
      return;
    }

    try {
      setLoading(true);
      const response = await apiLogin({
        userId: userId?.trim(),
        password: password?.trim(),
      });
      if (response?.header?.resultCode === "1000") {
        localStorage.setItem(
          "timesheet_userData",
          JSON.stringify(response?.records?.user)
        );
        dispatch(updateUserData(response?.records?.user));

        localStorage.setItem("timesheet_isLoggedIn", true);
        dispatch(loginSuccess());

        if (response?.records?.user.role === Role.User) {
          const newSite = {
            siteId: response?.records?.user.siteIds[0]?.siteId,
            siteName: response?.records?.user.siteIds[0]?.siteName,
          };
          setSiteLocal(newSite);
          dispatch(updateSelectedSiteReducer(newSite));

          navigate(Routes.ListReports.path);
        } else if (
          response?.records?.user.role === Role.Manage ||
          response?.records?.user.role === Role.Admin
        ) {
          navigate(Routes.Management.path);
        }

        if (rememberMe) {
          localStorage.setItem("timesheet_userId", userId);
          const encryptedPassword = CryptoJS.AES.encrypt(
            password,
            secretKey
          ).toString();
          localStorage.setItem("timesheet_password", encryptedPassword);
          localStorage.setItem("timesheet_rememberMe", rememberMe.toString());
        } else {
          localStorage.removeItem("timesheet_userId");
          localStorage.removeItem("timesheet_password");
          localStorage.removeItem("timesheet_rememberMe");
        }
      }
    } catch (error) {
      if (error.response) {
        // Access the response object in the error
        if (
          error.response.data.header?.message?.includes(
            "Please check your ID/Password again"
          ) ||
          error.response.data.header?.message?.includes("Validation Error")
        ) {
          setErrors({
            userId: " ",
            password: "The username or password is incorrect.",
          });
        } else if (
          error.response.data.header?.message?.includes(
            "Your site has been disabled. Please contact the administrator!"
          )
        ) {
          ToastFailed(
            "Your site has been disabled. Please contact the administrator!"
          );
        } else if (
          error.response.data.header?.message?.includes(
            "Your site has been deleted. Please contact the administrator!"
          )
        ) {
          ToastFailed(
            "Your site has been deleted. Please contact the administrator!"
          );
        }
      } else {
        // Handle other unexpected errors
        ToastFailed("Something went wrong, please try again!");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="login-page">
      <ToastContainer />
      <div className="brand">
        <div className="d-flex justify-content-center align-items-center">
          <img src={TimesSheetBrand} height={120} />
        </div>
      </div>
      <form onSubmit={handleLogin} className="form-login">
        <div className="form-login_title">
          <span>Login</span>
        </div>
        <div className="form-login_input">
          <span className="icon">
            <UserOutlined />
          </span>
          <input
            value={formInput.userId}
            type="text"
            className={`${
              errors.userId ||
              errors.password === "ID or password are incorrect!"
                ? "input-error"
                : ""
            }`}
            onChange={(e) => {
              handleOnChange(e, "userId");
            }}
            onBlur={() => handleBlur("userId")}
            placeholder="Login ID"
          />
          {errors.userId && (
            <div className="text-danger text-error">{errors.userId}</div>
          )}
        </div>
        <div className="form-login_input">
          <span className="icon" onClick={handleShowPassword}>
            <LockOutlined />
          </span>
          <div className="w-100 d-flex align-items-center input-password-login">
            <input
              value={formInput.password}
              type={isShowPassword ? "text" : "password"}
              className={`${errors.password ? "input-error" : ""}`}
              onChange={(e) => {
                handleOnChange(e, "password");
              }}
              placeholder="Password"
            />
            <img
              onClick={() => {
                handleShowPassword("newPassword");
              }}
              src={isShowPassword ? IconEyeShow : IconEyeHidden}
              alt="icon"
              width={20}
              height={20}
            />
          </div>
          {errors.password && (
            <div className="text-danger text-error">{errors.password}</div>
          )}
        </div>
        <div className="form-login_note">
          <div className="d-flex gap-2">
            <span>
              <Checkbox onChange={handleChecked} checked={rememberMe} />
            </span>
            <span>Remember me</span>
          </div>
          <div>※ Login ID and password are saved.</div>
        </div>
        <div className="d-flex justify-content-center">
          <Button title={"Sign In"} width={"114px"} loading={loading} />
        </div>
      </form>
    </div>
  );
};
