import { Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import { apiGetListEquipments } from "../../services/Equipment";
import { apiGetListManpowers } from "../../services/Manpower";
import { apiGetListWorkItems } from "../../services/WorkItem";
import TagInput from "./TagInput";
import { apiSettingSite } from "../../services/Site";
import { ReactComponent as IconPlus } from "../../assets/icons/icon-plus.svg";
import ModalAddNewEquiment from "../master_data/ModalAddNewEquiment";
import ModalAddNewManpower from "../master_data/ModalAddNewManpower";
import ModalAddNewWorItem from "../master_data/ModalAddNewWorItem";
import { ToastFailed, ToastSuccess } from "../common/Toast";
import useCheckIdExist from "../../hook/useCheckIdExist";
import useRemoveAllItemCheck from "../../hook/useRemoveAllItemCheck";
import CustomPopconfirm from "./PopupConfirmMasterData";

export default ({ isModalOpen, setIsModalOpen, siteId, dataDetail, setDataDetail }) => {
    const [loading, setLoading] = useState(false);
    const [isEditting, setIsEditting] = useState(false)
    const [inputXValue, setInputXValue] = useState('');
    const [inputYValue, setInputYValue] = useState('');
    const [inputZValue, setInputZValue] = useState('');

    const { loadingCheckId: loadingManpower, checkIdExist: checkManpowerIdExist } = useCheckIdExist();
    const { loadingCheckId: loadingEquipment, checkIdExist: checkEquipmentIdExist } = useCheckIdExist();
    const { loadingCheckId: loadingWorkItem, checkIdExist: checkWorkItemIdExist } = useCheckIdExist();

    const { loadingCheckListIds: loadingRemoveAllManpowers, checkReturnListIdsExist: checkRemoveAllManpowers } = useRemoveAllItemCheck();
    const { loadingCheckListIds: loadingRemoveAllEquipments, checkReturnListIdsExist: checkRemoveAllEquipments } = useRemoveAllItemCheck();
    const { loadingCheckListIds: loadingRemoveAllWorkItems, checkReturnListIdsExist: checkRemoveAllWorkItems } = useRemoveAllItemCheck();

    const [optionsManpower, setOptionsManpower] = useState([]);
    const [optionsEquipment, setOptionsEquipment] = useState([]);
    const [optionsWorkItem, setOptionsWorkItem] = useState([]);

    const [listManpowersSeleted, setListManpowersSelected] = useState([])
    const [listEquipmentsSeleted, setListEquipmentsSelected] = useState([])
    const [listWorkItemsSeleted, setListWorkItemsSelected] = useState([])

    const [x, setX] = useState([])
    const [y, setY] = useState([])
    const [z, setZ] = useState([])

    const [isOpenModalAddManpower, setIsOpenModalAddManpower] = useState(false)
    const [isOpenModalAddEquipment, setIsOpenModalAddEquipment] = useState(false)
    const [isOpenModalAddWorkItem, setIsOpenModalAddWorkItem] = useState(false)

    const handleOpenModalAdd = (modalName) => {
        if (modalName === "manpower") setIsOpenModalAddManpower(true)
        if (modalName === "equipment") setIsOpenModalAddEquipment(true)
        if (modalName === "work item") setIsOpenModalAddWorkItem(true)

        setIsModalOpen(false)
        setIsEditting(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        setX([])
        setY([])
        setZ([])
        setListEquipmentsSelected([])
        setListManpowersSelected([])
        setListWorkItemsSelected([])
        setInputXValue("")
        setInputYValue("")
        setInputZValue("")
        setIsEditting(false)
    }
    
    const handleGetListEquipments = async () => {
        try {
            const response = await apiGetListEquipments({
                pageIndex: 1,
                pageSize: 10000,
                textSearch: "",
                isGetDataWithNullSiteId: true,
                siteId
            })
            if (response?.header?.resultCode === "1000") {
                const data = response?.records?.listEquipments?.equipments
                if (data?.length > 0) {
                    setOptionsEquipment(data.map(item => ({
                        value: item.equipmentId,
                        label: item.name + "_" + item.power
                    })))
                }
            }
        } catch (e) {
            console.log(e);
        }

    }

    const handleGetListManpowers = async () => {
        try {
            const response = await apiGetListManpowers({
                pageIndex: 1,
                pageSize: 10000,
                textSearch: "",
                isGetDataWithNullSiteId: true,
                siteId
            })
            if (response?.header?.resultCode === "1000") {
                const data = response?.records?.listManpowers?.manpowers
                if (data?.length > 0) {
                    setOptionsManpower(data.map(item => ({
                        value: item.manpowerId,
                        label: item.name
                    })))
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
    const handleGetListWorkItems = async () => {
        try {
            const response = await apiGetListWorkItems({
                pageIndex: 1,
                pageSize: 10000,
                textSearch: "",
                isGetDataWithNullSiteId: true,
                siteId
            })
            if (response?.header?.resultCode === "1000") {
                const data = response?.records?.listWorkItems?.workItems
                if (data?.length > 0) {
                    setOptionsWorkItem(data.map(item => ({
                        value: item.workItemId,
                        label: item.name
                    })))
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleOnchange = async (value, keyName) => {
        setIsEditting(true)
        if (keyName === "manpower") {
            const deletedValues = listManpowersSeleted.filter(item => !value.includes(item));
            const addedValues = value.filter(item => !listManpowersSeleted.includes(item));

            if (deletedValues.length === 1) {
                const response = await checkManpowerIdExist({ siteId, entityName: "Manpower", masterDataId: deletedValues[0] });
                if (response?.header?.resultCode === "1000" && response?.records?.site === "MasterDataId exists in Report.") {
                    ToastFailed("This items is linked to a report. You cannot delete it.");
                } else {
                    setListManpowersSelected(value);
                }
            }

            if (deletedValues.length > 1) {
                // handle clear all
                const response = await checkRemoveAllManpowers({ siteId, entityName: "Manpower", masterDataIds: deletedValues.join("/") });
                if (response?.header?.resultCode === "1000") {
                    setListManpowersSelected(response?.records?.site);
                }
            }

            if (addedValues.length > 0) {
                setListManpowersSelected(value);
            }
        }

        if (keyName === "equipment") {
            const deletedValues = listEquipmentsSeleted.filter(item => !value.includes(item));
            const addedValues = value.filter(item => !listEquipmentsSeleted.includes(item));

            if (deletedValues.length === 1) {
                const response = await checkEquipmentIdExist({ siteId, entityName: "Equipment", masterDataId: deletedValues[0] });
                if (response?.header?.resultCode === "1000" && response?.records?.site === "MasterDataId exists in Report.") {
                    ToastFailed("This items is linked to a report. You cannot delete it.");
                } else {
                    setListEquipmentsSelected(value);
                }
            }
            if (deletedValues.length > 1) {
                // handle clear all
                const response = await checkRemoveAllEquipments({ siteId, entityName: "Equipment", masterDataIds: deletedValues.join("/") });
                if (response?.header?.resultCode === "1000") {
                    setListEquipmentsSelected(response?.records?.site);
                }
            }
            if (addedValues.length > 0) {
                setListEquipmentsSelected(value);
            }
        }

        if (keyName === "work item") {
            const deletedValues = listWorkItemsSeleted.filter(item => !value.includes(item));
            const addedValues = value.filter(item => !listWorkItemsSeleted.includes(item));

            if (deletedValues.length === 1) {
                const response = await checkWorkItemIdExist({ siteId, entityName: "WorkItem", masterDataId: deletedValues[0] });
                if (response?.header?.resultCode === "1000" && response?.records?.site === "MasterDataId exists in Report.") {
                    ToastFailed("This items is linked to a report. You cannot delete it.");
                } else {
                    setListWorkItemsSelected(value);
                }
            }
            if (deletedValues.length > 1) {
                // handle clear all
                const response = await checkRemoveAllWorkItems({ siteId, entityName: "WorkItem", masterDataIds: deletedValues.join("/") });
                if (response?.header?.resultCode === "1000") {
                    setListWorkItemsSelected(response?.records?.site);
                }
            }
            if (addedValues.length > 0) {
                setListWorkItemsSelected(value);
            }
        }
    };


    useEffect(() => {
        if (isModalOpen && siteId && dataDetail) {
            handleGetListEquipments()
            handleGetListManpowers()
            handleGetListWorkItems()
            if (dataDetail.manpowers.length > 0) {
                setListManpowersSelected(dataDetail.manpowers.map((item) => item.manpowerId))
            }
            if (dataDetail.equipments.length > 0) {
                setListEquipmentsSelected(dataDetail.equipments.map((item) => item.equipmentId))
            }
            if (dataDetail.workItems.length > 0) {
                setListWorkItemsSelected(dataDetail.workItems.map((item) => item.workItemId))
            }

            if (dataDetail.x) setX(dataDetail.x?.split("/"))
            if (dataDetail.y) setY(dataDetail.y?.split("/"))
            if (dataDetail.z) setZ(dataDetail.z?.split("/"))
        }
    }, [siteId, isModalOpen, dataDetail])


    const handleSubmit = async () => {
        try {
            setLoading(true)
            const response = await apiSettingSite({
                siteId,
                ManpowerIds: listManpowersSeleted.join("/"),
                EquipmentIds: listEquipmentsSeleted.join("/"),
                WorkItemIds: listWorkItemsSeleted.join("/"),
                x: x.join("/"),
                y: y.join("/"),
                z: z.join("/")
            })
            if (response?.header?.resultCode === "1000") {
                handleCancel()
                ToastSuccess("Setting site successfully.")
            }
        } catch (e) {
            console.log(e);
            ToastFailed("Setting site failed.")
        } finally {
            setLoading(false)
        }
    }

    const handleConfirm = async ()=> {
        try {
            const response = await apiSettingSite({
                siteId,
                ManpowerIds: listManpowersSeleted.join("/"),
                EquipmentIds: listEquipmentsSeleted.join("/"),
                WorkItemIds: listWorkItemsSeleted.join("/"),
                x: x.join("/"),
                y: y.join("/"),
                z: z.join("/")
            })
           return response;
        } catch (e) {
            console.log(e);
            ToastFailed("Setting site failed.")
        }
    }

    return (
        <>
            <ModalAddNewEquiment
                isModalOpen={isOpenModalAddEquipment}
                setIsModalOpen={setIsOpenModalAddEquipment}
                handleGetListEquipments={handleGetListEquipments}
                siteId={siteId}
                setIsOpenModalSettingSite={setIsModalOpen}
                setDataDetail={setDataDetail}
            />
            <ModalAddNewManpower
                isModalOpen={isOpenModalAddManpower}
                setIsModalOpen={setIsOpenModalAddManpower}
                handleGetListManpowers={handleGetListManpowers}
                siteId={siteId}
                setIsOpenModalSettingSite={setIsModalOpen}
                setDataDetail={setDataDetail}
            />
            <ModalAddNewWorItem
                isModalOpen={isOpenModalAddWorkItem}
                setIsModalOpen={setIsOpenModalAddWorkItem}
                handleGetListWorkItems={handleGetListWorkItems}
                siteId={siteId}
                setIsOpenModalSettingSite={setIsModalOpen}
                setDataDetail={setDataDetail}
            />
            <Modal title={"Site Setting"} open={isModalOpen} footer={null} className='modal-master-data'>
                <div className="master-data">
                    <div className="master-data_item">
                        <label className="d-flex justify-content-between align-items-center" onClick={(event) => event.stopPropagation()}> 
                            <span>Manpower</span>
                        </label>
                        <div className="icon-plus" style={{width:"30px"}} 
                            onClick={(event) => { event.stopPropagation();}}>
                            {isEditting? 
                                <CustomPopconfirm
                                    onConfirm={handleConfirm}
                                    onCancel={() => handleOpenModalAdd("manpower")}
                                    setIsEditting = {setIsEditting}
                                 />
                            :
                            <span className="cursor-pointer" onClick={() => handleOpenModalAdd("manpower")}><IconPlus /></span>
                            }
                        </div>
                        <Spin spinning={loadingManpower || loadingRemoveAllManpowers}>
                            <Select
                                className="w-100 ant-select-multiple"
                                mode="multiple"
                                allowClear
                                value={listManpowersSeleted}
                                placeholder="Select manpower"
                                onChange={(value) => handleOnchange(value, "manpower")}
                                options={optionsManpower}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Spin>
                    </div>
                    <div className="master-data_item">
                        <label className="d-flex justify-content-between align-items-center" onClick={(event) => event.stopPropagation()}>
                            <span>Equipment</span>
                        </label>
                        <div className="icon-plus" style={{width:"30px"}} 
                            onClick={(event) => { event.stopPropagation();}}>
                            {isEditting? 
                                <CustomPopconfirm
                                    onConfirm={handleConfirm}
                                    onCancel={() => handleOpenModalAdd("equipment")}
                                    setIsEditting = {setIsEditting}
                                 />
                            :
                            <span className="cursor-pointer" onClick={() => handleOpenModalAdd("equipment")}><IconPlus /></span>
                            }
                        </div>
                        <Spin spinning={loadingEquipment || loadingRemoveAllEquipments}>
                            <Select
                                className="w-100 ant-select-multiple"
                                mode="multiple"
                                allowClear
                                placeholder="Select equipment"
                                value={listEquipmentsSeleted}
                                onChange={(value) => handleOnchange(value, "equipment")}
                                options={optionsEquipment}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Spin>
                    </div>
                    <div className="master-data_item">
                        <label>
                            <div>Work item</div>
                        </label>
                        <div className="icon-plus" style={{width:"30px"}} 
                            onClick={(event) => { event.stopPropagation();}}>
                            {isEditting? 
                                <CustomPopconfirm
                                    onConfirm={handleConfirm}
                                    onCancel={() => handleOpenModalAdd("work item")}
                                    setIsEditting = {setIsEditting}
                                 />
                            :
                                <div className="cursor-pointer" onClick={() =>{ handleOpenModalAdd("work item")}}><IconPlus /></div>
                            }
                        </div>
                        <Spin spinning={loadingWorkItem || loadingRemoveAllWorkItems}>
                            <Select
                                className="w-100 ant-select-multiple"
                                mode="multiple"
                                allowClear
                                value={listWorkItemsSeleted}
                                placeholder="Select work item"
                                onChange={(value) => handleOnchange(value, "work item")}
                                options={optionsWorkItem}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Spin>
                    </div>
                    <div className="area-config">
                        <div>Location</div>
                        <div className="d-flex flex-column" style={{ gap: "10px" }}>
                            <TagInput title={"X"} tags={x} setTags={setX} inputValue={inputXValue} setInputValue={setInputXValue} siteId={siteId} setIsEditting = {setIsEditting}/>
                            <TagInput title={"Y"} tags={y} setTags={setY} inputValue={inputYValue} setInputValue={setInputYValue} siteId={siteId} setIsEditting = {setIsEditting}/>
                            <TagInput title={"Z"} tags={z} setTags={setZ} inputValue={inputZValue} setInputValue={setInputZValue} siteId={siteId} setIsEditting = {setIsEditting}/>
                        </div>
                    </div>
                </div>
                <div className="modal-master-data_footer">
                    <Button title={"Save"} width={"62px"} handleClick={handleSubmit} loading={loading} />
                    <Button title={"Cancel"} BgColor={"#fff"} fontColor={"#000000D9"} borderColor={"#D9D9D9"} handleClick={handleCancel} width={"75px"} />
                </div>
            </Modal>
        </>
    );
};
