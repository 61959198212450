export const formatString = (str, maxLength, totalMaxLength) => {
  // Cắt chuỗi nếu vượt quá totalMaxLength
  const truncatedStr =
    str.length > totalMaxLength ? str.slice(0, totalMaxLength) + "..." : str;
  // Tách chuỗi thành các dòng
  const regex = new RegExp(`.{1,${maxLength}}`, "g");
  return truncatedStr.match(regex) || [];
};

export function formatStringWord(input, maxWordLength, maxTotalLength) {
  if (!input) return [];

  const words = input.split(" ");
  let result = [];
  let currentLine = "";

  for (let word of words) {
    if (word.length > maxWordLength) {
      // Nếu từ dài hơn maxWordLength, chia nhỏ từ
      const chunks = word.match(new RegExp(`.{1,${maxWordLength}}`, "g")) || [];
      result.push(...chunks);
    } else {
      // Kiểm tra nếu thêm từ này vào dòng hiện tại có vượt maxTotalLength không
      if ((currentLine + " " + word).trim().length > maxTotalLength) {
        result.push(currentLine.trim());
        currentLine = word;
      } else {
        currentLine += " " + word;
      }
    }
  }

  // Đẩy dòng cuối cùng vào kết quả nếu không rỗng
  if (currentLine.trim()) result.push(currentLine.trim());

  return result;
}
