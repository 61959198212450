import client from "../api/axiosClient";

const apiCheckExistWorkPlan = (data) => {
  return client.post("/api/workplan/check-workplan-exist", {
    location: data.location,
    workItemId: data.workItemId,
    siteId: data.siteId,
  });
};
const apiDeleteWorkPlan = (id, siteId) => {
  return client.delete(`/api/workplan/delete/${id}/${siteId}`);
};
const apiGetListWorkPlans = (data) => {
  return client.post(`/api/workplan/list`, data);
};
const apiSaveWorkPlans = (data) => {
  return client.put(`/api/workplan/createorupdate`, {
    siteId: data.siteId,
    workPlans: data.workPlans,
  });
};
const apiCreateOneWorkPlan = (data) => {
  return client.post("/api/workplan/createOneWorkPlan", data);
};
export {
  apiCheckExistWorkPlan,
  apiDeleteWorkPlan,
  apiGetListWorkPlans,
  apiSaveWorkPlans,
  apiCreateOneWorkPlan,
};
