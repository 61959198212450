import client from "../api/axiosClient";

const apiGetListReport = async (request) => {
  return await client.post("/api/report/list", request);
};

const apiCreateReport = (data) => {
  return client.post("/api/report/create", data);
};

const apiEditReport = (data) => {
  return client.put("/api/report/update", data);
};

const apiDetailReport = (id) => {
  return client.get(`/api/report/detail/${id}`);
};

const apiDeleteReport = (id) => {
  return client.delete(`/api/report/delete/${id}`);
};

const getReportByModel = async (request) => {
  return await client.post("/api/report/getReportByModel", request);
};

const getLstLocationGroup = async (request) => {
  return await client.post("/api/report/getLstLocationGroup", request);
};

const getListProgressReport = async (request) => {
  return await client.post("/api/report/getListProgressReport", request);
};

const progressReportDetail = async (request) => {
  return await client.post("/api/report/progressReportDetail", request);
};

const getListReportAccomplishment = async (request) => {
  return await client.post("/api/report/getListReportAccomplishment", request);
};

const updateListAccomplishment = async (request) => {
  return await client.post("/api/report/updateListAccomplishment", request);
};

export {
  getReportByModel,
  apiCreateReport,
  getLstLocationGroup,
  apiGetListReport,
  apiDeleteReport,
  apiDetailReport,
  apiEditReport,
  getListProgressReport,
  progressReportDetail,
  getListReportAccomplishment,
  updateListAccomplishment,
};
