import React, { useEffect, useState } from "react";
import "./style.scss";
import { BreadcrumbHeader } from "../../component/common/BreadcrumbHeader";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Button from "../../component/common/Button";
import { Select } from "antd";
import ModalAddUser from "../../component/user_management/ModalAddUser";
import PaginationCommon from "../../component/common/Pagination";
import { apiGetListUsers } from "../../services/User";
import { Role } from "../../utils/Role";
import TableAdministrator from "../../component/user_management/TableAdministrator";
import { apiGetListSites, apiGetListSitesEnable } from "../../services/Site";
import { ToastContainer } from "react-toastify";
const items = [
  {
    title: "User",
  },
  {
    title: "Administrator",
  },
];

export default () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listUsers, setListUsers] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [optionsSite, setOptionsSite] = useState([]); //all sites include status enable and disable
  const [optionsSiteEnable, setOptionsSiteEnable] = useState([]);
  const role = JSON.parse(localStorage.getItem("timesheet_userData")).role;
  const [siteSelected, setSiteSelected] = useState("");
  const [search, setSearch] = useState("");
  const [sorterInfo, setSorterInfo] = useState({});

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleInputSearch = (e) => {
    setPageIndex(1);
    setSearch(e.target.value);
  };

  const onChangeSite = (value) => {
    if (value) {
      setSiteSelected(value);
    } else {
      setSiteSelected("");
    }
  };

  const handleGetListUsers = async (page = 1) => {
    let formParams = {
      pageIndex: page,
      pageSize,
      siteId: siteSelected,
      textSearch: search,
      isAdmin: true,
      field: sorterInfo?.field ? sorterInfo.field : "",
      order: sorterInfo?.order ? sorterInfo.order : "",
    };
    try {
      const response = await apiGetListUsers(formParams);
      if (response?.header?.resultCode === "1000") {
        const userConfig = response?.records?.listUser?.users.map(
          (item, index) => {
            return {
              ...item,
              index: (pageIndex - 1) * pageSize + index + 1,
              key: (pageIndex - 1) * pageSize + index + 1,
              lastModifiedBy: item.lastModifiedBy ? item.lastModifiedBy : "---",
            };
          }
        );
        setListUsers(userConfig);
        setTotalRecord(response?.records?.listUser?.totalRecord);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    handleGetListUsers(pageIndex);
  }, [pageIndex, pageSize, siteSelected, search, sorterInfo]);

  const handleGetListSite = async () => {
    if (role === Role.Admin) {
      try {
        const response = await apiGetListSites({
          pageIndex: 1,
          pageSize: 10000,
          textSearch: "",
        });
        if (response?.header?.resultCode === "1000") {
          const listSites = response.records.listSite.sites;
          const newOptions = listSites.map((item) => ({
            label: item.siteName,
            value: item.siteId,
          }));
          setOptionsSite(newOptions);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleGetListSiteEnable = async () => {
    if (role === Role.Admin) {
      try {
        const response = await apiGetListSitesEnable({
          pageIndex: 1,
          pageSize: 10000,
        });
        if (response?.header?.resultCode === "1000") {
          const listSites = response.records.listSite.sites;
          const newOptions = listSites.map((item) => ({
            label: item.siteName,
            value: item.siteId,
          }));
          setOptionsSiteEnable(newOptions);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    handleGetListSite();
    handleGetListSiteEnable();
  }, []);

  return (
    <>
      <ToastContainer />
      <BreadcrumbHeader title={"Administrator"} items={items} />
      <div className="content">
        <ModalAddUser
          setPageIndex={setPageIndex}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleGetListUsers={handleGetListUsers}
          optionsSite={optionsSiteEnable}
        />
        <div className="report-page user-managemant-page p-4">
          <div className="daily-report">
            <div className="d-flex justify-content-between align-items-center">
              <div className="total-report">{totalRecord} users</div>
              <div className="d-flex gap-2">
                <Select
                  style={{ width: "204px" }}
                  showSearch
                  allowClear
                  placeholder="Select Site"
                  optionFilterProp="label"
                  onChange={onChangeSite}
                  value={siteSelected ? siteSelected : undefined}
                  options={optionsSite}
                />
                <div className="form-input">
                  <input
                    placeholder="Search"
                    value={search}
                    onChange={handleInputSearch}
                    maxLength={100}
                  />
                  <div className="icon">
                    <SearchOutlined />
                  </div>
                </div>
                <Button
                  title={"Add"}
                  icon={<PlusOutlined />}
                  handleClick={showModal}
                />
              </div>
            </div>
            <div style={{ flexGrow: 1 }} className="table-administrator">
              <TableAdministrator
                setSorterInfo={setSorterInfo}
                setPageIndex={setPageIndex}
                listUsers={listUsers}
                handleGetListUsers={handleGetListUsers}
                optionsSite={optionsSite}
                optionsSiteEnable={optionsSiteEnable}
                pageSize={pageSize}
              />
            </div>
            <div className="text-end">
              <PaginationCommon
                totalRecord={totalRecord}
                pageSize={pageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
