import React, { useEffect, useState } from "react";
import { BreadcrumbHeader } from "../../component/common/BreadcrumbHeader";
import "./style.scss";
import { SearchOutlined } from "@ant-design/icons";
import { DatePicker, Space } from "antd";
import Button from "../../component/common/Button";
import TableProgressReport from "../../component/report/report_list/TableProgressReport";
import { exportListReportSite } from "../../services/ExportData";
import { PAGESIZE, SITENAME } from "../../constants";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getListProgressReport } from "../../services/Report";
import PaginationCommon from "../../component/common/Pagination";
import { getSiteId } from "../../utils/auth";
import { useSelector } from "react-redux";
import { ToastFailed } from "../../component/common/Toast";
import { ToastContainer } from "react-toastify";
const { RangePicker } = DatePicker;
dayjs.extend(utc);
const items = [
  {
    title: "Progress report",
  },
];

export default () => {
  let siteId =
    useSelector((state) => state.site).selectedSite?.siteId ||
    JSON.parse(getSiteId())?.siteId;
  const [modelSearch, setModelSearch] = useState({
    StartDate: dayjs().subtract(7, "day"),
    EndDate: dayjs(),
    SiteId: siteId,
    Search: null,
    CurrentPage: 1,
    DisplayItem: PAGESIZE,
  });

  const [rangeValue, setRangeValue] = useState([
    dayjs().subtract(7, "day"),
    dayjs(),
  ]);
  const handleDateChange = (dates) => {
    setPageIndex(1);
    if (dates) {
      // Cập nhật modelSearch
      const [start, end] = dates;
      setRangeValue(dates);
      setModelSearch({
        ...modelSearch,
        StartDate: start,
        EndDate: end,
      });
    } else {
      setRangeValue([]);
      setModelSearch({
        ...modelSearch,
        StartDate: null,
        EndDate: null,
      });
    }
  };

  const handleInputChange = (e) => {
    setPageIndex(1);
    setModelSearch({
      ...modelSearch,
      Search: e.target.value,
    });
  };

  const pad2 = (n) => {
    return n < 10 ? "0" + n : n;
  };
  let nameSite =
    useSelector((state) => state.site).selectedSite?.siteName ||
    JSON.parse(getSiteId())?.siteName;
  const [loading, setLoading] = useState(false);
  const exportData = async () => {
    setLoading(true);
    let date = new Date();
    var request = {
      SiteId: siteId,
      LocationWorkItem: lstSelect, //là 1 list string có dạng location-workitemId vd 2Hc-1
      StartDate: modelSearch.StartDate
        ? dayjs(modelSearch.StartDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
        : null,
      EndDate: modelSearch.EndDate
        ? dayjs(modelSearch.EndDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
        : null,
    };
    try {
      const response = await exportListReportSite(request);
      const url = URL.createObjectURL(
        new Blob([response], {
          type: "application/vnd.ms-excel;charset=utf-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      let fileName =
        "Report" +
        "_" +
        nameSite +
        "_" +
        date.getFullYear().toString() +
        pad2(date.getMonth() + 1) +
        pad2(date.getDate()) +
        ".xlsx";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        // Access the response object in the error
        if (error.response.status === 500) {
          ToastFailed("There is no data to export.");
        }
      } else {
        // Handle other unexpected errors
        ToastFailed("Something went wrong, please try again!");
      }
    }
  };

  const [totalRecord, setTotalRecord] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(PAGESIZE);
  const [pageReports, setPageReports] = useState([]);
  const getListData = async () => {
    var request = modelSearch;
    request.CurrentPage = pageIndex;
    request.DisplayItem = pageSize;
    request.SiteId = siteId;
    request.StartDate = modelSearch.StartDate
      ? dayjs(modelSearch.StartDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
      : null;
    request.EndDate = modelSearch.EndDate
      ? dayjs(modelSearch.EndDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
      : null;
    try {
      const response = await getListProgressReport(request);
      if (response?.header?.resultCode === "1000") {
        setPageReports(response?.records?.listReports?.reports);
        setTotalRecord(response?.records?.listReports?.totalRecord);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [lstSelect, setLstSelect] = useState([]);
  const updateLstSelect = (newValue) => {
    setLstSelect(newValue);
  };

  useEffect(() => {
    setModelSearch({
      ...modelSearch,
      CurrentPage: pageIndex,
      DisplayItem: pageSize,
    });
    getListData();
  }, [
    pageIndex,
    pageSize,
    siteId,
    modelSearch.StartDate,
    modelSearch.EndDate,
    modelSearch.Search,
  ]);

  return (
    <>
      <ToastContainer />
      <div className="work-plan_title">
        <BreadcrumbHeader title={"Progress report"} items={items} />
      </div>
      <div className="content">
        <div className="report-page p-4">
          <div className="daily-report">
            <div className="d-flex justify-content-between align-items-center">
              <div className="total-report">{totalRecord} reports</div>
              <div className="d-flex gap-2">
                <Space direction="vertical" size={12}>
                  <RangePicker
                    format="YYYY-MM-DD"
                    value={rangeValue}
                    onChange={handleDateChange}
                  />
                </Space>
                <div className="form-input">
                  <input
                    placeholder="Search"
                    maxLength={100}
                    value={modelSearch.Search}
                    onChange={handleInputChange}
                  />
                  <div className="icon">
                    <SearchOutlined />
                  </div>
                </div>
                <Button
                  title={lstSelect.length > 0 ? "Export" : "Export all"}
                  fontColor={"#fff"}
                  BgColor={"#39AF01"}
                  borderColor={"#39AF01"}
                  handleClick={exportData}
                  loading={loading}
                />
              </div>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TableProgressReport
                pageReports={pageReports}
                modelSearch={modelSearch}
                onUpdateLstSelect={updateLstSelect}
                pageSize={pageSize}
                pageIndex={pageIndex}
              />
            </div>
            <div className="text-end">
              <PaginationCommon
                totalRecord={totalRecord}
                pageSize={pageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
