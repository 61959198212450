import client from "../api/axiosClient";

const apiCreateManpower = (data) => {
  return client.post("/api/manpower/create", data);
};

const apiEditManpower = (data) => {
  return client.put("/api/manpower/update", data);
};

const apiDeleteManpower = (id) => {
  return client.delete(`/api/manpower/delete/${id}`);
};

const apiGetListManpowers = ({
  pageIndex,
  pageSize,
  textSearch,
  isGetDataWithNullSiteId,
  siteId,
  field,
  order,
}) => {
  return client.get(
    `/api/manpower/list?currentPage=${pageIndex}&DisplayItem=${pageSize}&textSearch=${textSearch}&IsGetDataWithNullSiteId=${isGetDataWithNullSiteId}&SiteId=${siteId}&Field=${field}&Order=${order}`
  );
};

const apiDetailManpower = (id) => {
  return client.get(`/api/manpower/detail/${id}`);
};

const apiDetailManpowerBySite = (siteId) => {
  return client.get(`/api/manpower/detailBySite/${siteId}`);
};

export {
  apiCreateManpower,
  apiEditManpower,
  apiDeleteManpower,
  apiGetListManpowers,
  apiDetailManpower,
  apiDetailManpowerBySite,
};
