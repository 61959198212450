import React, { useRef, useState } from "react";
import { Input, Tag, Button, Spin } from "antd";
import { apiCheckLocationExistInReport } from "../../services/Site";
import { ToastFailed } from "../common/Toast";
import { CloseOutlined } from "@ant-design/icons";

const TagInput = ({
  title,
  tags,
  setTags,
  placeholder,
  inputValue,
  setInputValue,
  siteId,
  setIsEditting,
}) => {
  const inputRef = useRef(null);
  const [loadingTag, setLoadingTag] = useState(null); // Trạng thái loading cho tag đang xóa

  const handleInputChange = (e) => {
    if (e.target.value.length <= 60) {
      const sanitizedValue = e.target.value.replace(/;/g, "");
      setInputValue(sanitizedValue);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === " " && inputValue.trim()) {
      addTags([inputValue.trim()]);
      setInputValue("");
    } else if ((e.key === "Delete" || e.key === "Backspace") && !inputValue) {
      handleRemoveLastTag();
    }
  };

  const handleBlur = () => {
    if (inputValue.trim()) {
      addTags([inputValue.trim()]);
      setInputValue("");
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    const sanitizedData = pasteData.replace(/;/g, ""); // Loại bỏ dấu `;`
    const words = sanitizedData.trim().split(/\s+/); // Tách các từ
    addTags(words);
    setInputValue("");
    e.preventDefault();
  };

  const addTags = (newTags) => {
    const processedTags = newTags.map((tag) =>
      tag.length > 60 ? tag.slice(0, 60) : tag
    );
    let uniqueTags = Array.from(
      new Set([
        ...tags,
        ...processedTags.filter((tag) => tag && !tags.includes(tag)),
      ])
    );
    let validTags = [];
    let totalLength = 0;

    for (const tag of uniqueTags) {
      const newTotalLength =
        totalLength + tag.length + (validTags.length > 0 ? 1 : 0);
      if (newTotalLength <= 255) {
        validTags.push(tag);
        totalLength = newTotalLength;
      } else {
        break;
      }
    }

    setTags(validTags);
    setIsEditting(true);
  };

  const handleClose = async (removedTag) => {
    setLoadingTag(removedTag);

    try {
      const response = await apiCheckLocationExistInReport({
        siteId,
        fieldName: title.toLowerCase(),
        fieldData: removedTag,
      });

      if (response?.header?.resultCode === "1000") {
        if (response?.records?.site === "data exists in Report.") {
          ToastFailed(
            "This items is linked to a report. You cannot delete it."
          );
        } else {
          setTags(tags.filter((tag) => tag !== removedTag));
          setIsEditting(true);
        }
      }
    } catch (e) {
      console.error("Error while checking tag existence:", e);
    } finally {
      setLoadingTag(null);
    }
  };

  const handleRemoveLastTag = async () => {
    const lastTag = tags[tags.length - 1];
    if (!lastTag) return;

    setLoadingTag(lastTag);

    try {
      const response = await apiCheckLocationExistInReport({
        siteId,
        fieldName: title.toLowerCase(),
        fieldData: lastTag,
      });

      if (response?.header?.resultCode === "1000") {
        if (response?.records?.site === "data exists in Report.") {
          ToastFailed("This item is linked to a report. You cannot delete it.");
        } else {
          setTags(tags.slice(0, -1));
          setIsEditting(true);
        }
      }
    } catch (e) {
      console.error("Error while checking tag existence:", e);
    } finally {
      setLoadingTag(null);
    }
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="are-config_item">
      <label>{title}</label>
      <div
        className="tags-input-wrapper"
        onClick={focusInput}
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        {tags?.map((tag) => (
          <Tag
            key={tag}
            style={{
              marginRight: 5,
              display: "flex",
              alignItems: "center",
              height: "30px",
            }}
          >
            {tag}
            <Button
              type="text"
              className="d-flex justify-content-center align-items-center"
              icon={
                loadingTag === tag ? (
                  <Spin size="small" />
                ) : (
                  <CloseOutlined style={{ fontSize: "10px", color: "gray" }} />
                )
              }
              onClick={() => handleClose(tag)}
              disabled={loadingTag === tag}
              style={{
                marginLeft: 8,
                padding: 0,
                color: "black",
                borderRadius: "50%",
                height: "20px",
                width: "20px",
                boxShadow: "0px 1px 2px 0px #00000012",
              }}
            />
          </Tag>
        ))}
        <div style={{ flex: "1 1 auto", minWidth: "200px" }}>
          <Input
            ref={inputRef}
            allowClear
            style={{ width: "100%" }}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onBlur={handleBlur}
            onPaste={handlePaste}
            placeholder={placeholder}
            bordered={false}
          />
        </div>
      </div>
    </div>
  );
};

export default TagInput;
