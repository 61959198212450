import React, { createContext, useContext, useEffect, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { Checkbox, Modal, Progress, Select, Space, Table, Tooltip } from "antd";
import Button from "../../common/Button";
import TableView from "../../common/TableView";
import { ReactComponent as IconEye } from "../../../assets/icons/icon-eye.svg";
import { exportReportSite } from "../../../services/ExportData";
import { progressReportDetail } from "../../../services/Report";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getSiteId, getUser } from "../../../utils/auth";
import { useSelector } from "react-redux";
import { ToastFailed } from "../../common/Toast";
import { formatNumberSimple } from "../../../helps/Format";
import { formatString } from "../../../utils/FormatString";
dayjs.extend(utc);
const DragIndexContext = createContext({
  active: -1,
  over: -1,
});
const dragActiveStyle = (dragState, id) => {
  const { active, over, direction } = dragState;
  // drag active style
  let style = {};
  if (active && active === id) {
    style = {
      backgroundColor: "gray",
      opacity: 0.5,
    };
  }
  // dragover dashed style
  else if (over && id === over && active !== over) {
    style =
      direction === "right"
        ? {
            borderRight: "1px dashed gray",
          }
        : {
            borderLeft: "1px dashed gray",
          };
  }
  return style;
};
const TableBodyCell = (props) => {
  const dragState = useContext(DragIndexContext);
  return (
    <td
      {...props}
      style={{
        ...props.style,
        ...dragActiveStyle(dragState, props.id),
      }}
    />
  );
};
const TableHeaderCell = (props) => {
  const dragState = useContext(DragIndexContext);
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: props.id,
  });
  const style = {
    ...props.style,
    cursor: "move",
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
          userSelect: "none",
        }
      : {}),
    ...dragActiveStyle(dragState, props.id),
  };
  return (
    <th
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

const baseColumnsArea = [
  {
    title: "Date",
    dataIndex: "date",
    width: "10%",
    render: (date) => dayjs(date).format("YYYY/MM/DD"), // Định dạng ngày ở đây
  },
  {
    title: "Accomplishment",
    dataIndex: "accomplishment",
    width: "10%",
    render: (value) => <div>{formatNumberSimple(value)}</div>,
  },
  {
    title: "Plan",
    dataIndex: "plan",
    width: "10%",
    render: (value) => <div>{formatNumberSimple(value)}</div>,
  },
  {
    title: "Accomplishment (Cumulative total)",
    dataIndex: "accomplishmentTotal",
    width: "15%",
    render: (value) => <div>{formatNumberSimple(value)}</div>,
  },
  {
    title: "Planned (Cumulative total)",
    dataIndex: "planCumulation",
    width: "15%",
    render: (value) => <div>{formatNumberSimple(value)}</div>,
  },
  {
    title: "Accomplishment Rate",
    dataIndex: "accomplishmentRate",
    key: "y",
    width: "20%",
    render: (value) => (
      <div style={{ alignItems: "center" }}>
        <Progress
          style={{ width: "80%" }}
          percent={value}
          status={value >= 100 ? "success" : "normal"}
          format={() => `${value}%`}
        />
      </div>
    ),
  },
  {
    title: "Plan progress rate",
    dataIndex: "progressRate",
    key: "x",
    width: "20%",
    render: (value) => (
      <div style={{ alignItems: "center" }}>
        <Progress
          style={{ width: "80%" }}
          percent={value}
          status={value >= 100 ? "success" : "normal"}
          format={() => `${value >= 100 ? 100 : value}%`}
        />
      </div>
    ),
  },
];

const TableProgressReport = ({
  pageReports,
  modelSearch,
  onUpdateLstSelect,
  pageSize,
  pageIndex,
}) => {
  const user = JSON.parse(getUser());
  let siteId =
    useSelector((state) => state.site).selectedSite?.siteId ||
    JSON.parse(getSiteId())?.siteId;
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceArea, setDataSourceArea] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modelDetail, setModelDetail] = useState({
    UserId: user.userId,
    StartDate: dayjs(modelSearch.StartDate)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]"),
    EndDate: dayjs(modelSearch.EndDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
    FomatPlan: 0,
    FomatPlanRate: 0,
    SiteId: siteId,
    Location: null,
    WorkItemId: null,
  });

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [pageIndex]);

  const showModal = () => {
    setIsModalOpen(true);
    if (keyLocation && keyWorkItem) {
      loadDataDetail();
    }
  };

  const loadDataDetail = async () => {
    try {
      var request = modelDetail;
      request.Location = keyLocation;
      request.WorkItemId = keyWorkItem;
      request.StartDate = modelSearch.StartDate
        ? dayjs(modelSearch.StartDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
        : null;
      request.EndDate = modelSearch.EndDate
        ? dayjs(modelSearch.EndDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
        : null;
      request.SiteId = siteId;
      const response = await progressReportDetail(request);
      if (response?.header?.resultCode === "1000") {
        setDataSourceArea(
          response?.records?.listReports.map((item, index) => ({
            key: index,
            date: item.date,
            accomplishment: item.accomplishment,
            plan: item.plan,
            accomplishmentTotal: item.accomplishmentCT,
            planCumulation: item.planCT,
            accomplishmentRate: item.accomplishmentRate,
            progressRate: item.planRate,
          }))
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancel = () => {
    setKeyDetail(null);
    setKeyLocation(null);
    setKeyWorkItem(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (pageReports.length > 0) {
      setDataSource(
        pageReports.map((item, index) => ({
          key: index,
          location: item.location,
          locationSplit: item.locationSplit,
          workItem: item.workItemName,
          userName: item.userName,
          volume: item.designVolumme,
          progress: item.planningProgressRate,
          workItemId: item.workItemId,
        }))
      );
    } else {
      setDataSource([]);
    }
  }, [pageReports]);

  const [keyDetail, setKeyDetail] = useState(null);
  const [keyLocation, setKeyLocation] = useState(null);
  const [keyWorkItem, setKeyWorkItem] = useState(null);
  const handleView = (record) => {
    setKeyDetail(record.location + "-" + record.workItem);

    setKeyLocation(record.locationSplit);
    setKeyWorkItem(record.workItemId);
    showModal();
  };

  const handleChangePlan = (value) => {
    setModelDetail({ ...modelDetail, FomatPlan: value });
  };

  const handleChangePlanRate = (value) => {
    setModelDetail({ ...modelDetail, FomatPlanRate: value });
  };

  useEffect(() => {
    if (keyLocation && keyWorkItem) {
      loadDataDetail();
    }
  }, [
    modelDetail.FomatPlan,
    modelDetail.FomatPlanRate,
    keyLocation,
    keyWorkItem,
  ]);

  const baseColumns = [
    {
      title: "Location",
      dataIndex: "location",
      render: (text) => {
        return text && text.length > 50 ? (
          <Tooltip placement="topRight" title={text}>
            <div className="item-show_data">
              {formatString(text, 25, 50).map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          </Tooltip>
        ) : (
          <div className="item-show_data">
            <div className="item-show_data">
              {formatString(text, 25, 50).map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      title: "Work item",
      dataIndex: "workItem",
    },
    {
      title: "User Name",
      dataIndex: "userName",
    },
    {
      title: "Design volume",
      dataIndex: "volume",
      render: (value) => <div>{formatNumberSimple(value)}</div>,
    },
    {
      title: "Planning progress rate",
      dataIndex: "progress",
      key: "x",
      render: (value, record) => (
        <div style={{ alignItems: "center" }}>
          <Progress
            style={{ width: "80%" }}
            percent={value}
            status={value >= 100 ? "success" : "normal"}
            format={() => `${value >= 100 ? 100 : value}%`}
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <div className="d-flex px-2 gap-4">
          <div
            onClick={() => handleView(record)}
            style={{ color: "#1890FF", cursor: "pointer" }}
          >
            <IconEye />
          </div>
        </div>
      ),
    },
  ];
  const [dragIndex, setDragIndex] = useState({
    active: -1,
    over: -1,
  });
  const [columns, setColumns] = useState(() =>
    baseColumns.map((column) => ({
      ...column,
      key: column.dataIndex,
      onHeaderCell: () => ({
        id: column.dataIndex,
      }),
      onCell: () => ({
        id: column.dataIndex,
      }),
    }))
  );
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  );
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setColumns((prevState) => {
        const activeIndex = prevState.findIndex((i) => i.key === active?.id);
        const overIndex = prevState.findIndex((i) => i.key === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
    setDragIndex({
      active: -1,
      over: -1,
    });
  };
  const onDragOver = ({ active, over }) => {
    const activeIndex = columns.findIndex((i) => i.key === active.id);
    const overIndex = columns.findIndex((i) => i.key === over?.id);
    setDragIndex({
      active: active.id,
      over: over?.id,
      direction: overIndex > activeIndex ? "right" : "left",
    });
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleUpdateSearch = (selectedRowKeys) => {
    onUpdateLstSelect(
      selectedRowKeys.map(
        (key) =>
          `${pageReports[key].locationSplit};${pageReports[key].workItemId}`
      )
    );
  };

  useEffect(() => {
    handleUpdateSearch(selectedRowKeys);
  }, [selectedRowKeys]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [siteId]);

  let nameSite =
    useSelector((state) => state.site).selectedSite?.siteName ||
    JSON.parse(getSiteId())?.siteName;
  const pad2 = (n) => {
    return n < 10 ? "0" + n : n;
  };
  const [loading, setLoading] = useState(false);
  const exportData = async () => {
    setLoading(true);
    let date = new Date();
    var request = {
      SiteId: siteId,
      Location: keyLocation,
      WorkItemId: keyWorkItem,
      StartDate: modelSearch.StartDate
        ? dayjs(modelSearch.StartDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
        : null,
      EndDate: modelSearch.EndDate
        ? dayjs(modelSearch.EndDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
        : null,
      FomatPlan: modelDetail.FomatPlan,
      FomatPlanRate: modelDetail.FomatPlanRate,
    };
    try {
      const response = await exportReportSite(request);
      const url = URL.createObjectURL(
        new Blob([response], {
          type: "application/vnd.ms-excel;charset=utf-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      let fileName =
        "Report" +
        "_" +
        nameSite +
        "_" +
        date.getFullYear().toString() +
        pad2(date.getMonth() + 1) +
        pad2(date.getDate()) +
        ".xlsx";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      ToastFailed("Export data false please try again!");
      console.log(e);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      collisionDetection={closestCenter}
    >
      <Modal
        title={keyDetail}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="modal-detail-sum-report"
      >
        <div className="d-flex justify-content-end">
          <Button
            title={"Export"}
            BgColor={"#39AF01"}
            borderColor={"#39AF01"}
            handleClick={exportData}
            loading={loading}
          />
        </div>
        <div className="frame-table-export">
          <div className="d-flex" style={{ height: "56px" }}>
            <div style={{ width: "535px" }}></div>
            <div
              style={{ display: "flex", alignItems: "center", padding: "16px" }}
            >
              <span style={{ marginRight: "8px" }}>Decimal Place</span>
              <div style={{ flexGrow: 1 }} className="px-3">
                <Space wrap>
                  <Select
                    style={{
                      width: 50,
                    }}
                    value={modelDetail.FomatPlan}
                    options={[
                      { label: "0", value: 0 },
                      { label: "1", value: 1 },
                      { label: "2", value: 2 },
                      { label: "3", value: 3 },
                    ]}
                    onChange={handleChangePlan}
                  />
                </Space>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "16px",
                marginLeft: "auto",
              }}
            >
              <span style={{ marginRight: "8px" }}>Decimal Place (%)</span>
              <div>
                <Space wrap>
                  <Select
                    style={{
                      width: 50,
                    }}
                    value={modelDetail.FomatPlanRate}
                    options={[
                      { label: "0", value: 0 },
                      { label: "1", value: 1 },
                      { label: "2", value: 2 },
                      { label: "3", value: 3 },
                    ]}
                    onChange={handleChangePlanRate}
                  />
                </Space>
              </div>
            </div>
          </div>
          <div className="form-table-view">
            <TableView
              baseColumns={baseColumnsArea}
              dataSource={dataSourceArea}
            />
          </div>
        </div>
      </Modal>
      <SortableContext
        items={columns.map((i) => i.key)}
        strategy={horizontalListSortingStrategy}
      >
        <DragIndexContext.Provider value={dragIndex}>
          <Table
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
            rowSelection={rowSelection}
            pagination={{
              pageSize: pageSize,
            }}
            components={{
              header: {
                cell: TableHeaderCell,
              },
              body: {
                cell: TableBodyCell,
              },
            }}
          />
        </DragIndexContext.Provider>
      </SortableContext>
      <DragOverlay>
        <th
          style={{
            backgroundColor: "gray",
            padding: 16,
          }}
        >
          {columns[columns.findIndex((i) => i.key === dragIndex.active)]
            ?.title || ""}
        </th>
      </DragOverlay>
    </DndContext>
  );
};
export default TableProgressReport;
