import React, { useEffect, useState } from "react";
import "./style.scss";
import { BreadcrumbHeader } from "../../component/common/BreadcrumbHeader";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Button from "../../component/common/Button";
import PaginationCommon from "../../component/common/Pagination";
import ModalAddNewEquiment from "../../component/master_data/ModalAddNewEquiment";
import TableEquipment from "../../component/master_data/TableEquipment";
import { apiGetListEquipments } from "../../services/Equipment";
import { ToastContainer } from "react-toastify";
import { Role } from "../../utils/Role";
import { useSelector } from "react-redux";
const items = [
  {
    title: "Master Data",
  },
  {
    title: "Equipment",
  },
];

export default () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [listData, setListData] = useState([]);
  const [search, setSearch] = useState("");
  const [sorterInfo, setSorterInfo] = useState({});
  const userRole =
    useSelector((state) => state.auth.userData?.role) ||
    JSON.parse(localStorage.getItem("timesheet_userData"))?.role;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleInputSearch = (e) => {
    setPageIndex(1);
    setSearch(e.target.value);
  };

  const handleGetListEquipments = async (page = 1) => {
    let formParams = {
      pageIndex: page,
      pageSize,
      textSearch: search,
      isGetDataWithNullSiteId: false,
      field: sorterInfo?.field ? sorterInfo.field : "",
      order: sorterInfo?.order ? sorterInfo.order : "",
    };
    try {
      const response = await apiGetListEquipments(formParams);
      if (response?.header?.resultCode === "1000") {
        const dataConfig = response?.records?.listEquipments?.equipments.map(
          (item, index) => {
            return {
              ...item,
              index: (pageIndex - 1) * pageSize + index + 1,
              key: (pageIndex - 1) * pageSize + index + 1,
            };
          }
        );
        setListData(dataConfig);
        setTotalRecord(response?.records?.listEquipments?.totalRecord);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleGetListEquipments(pageIndex);
  }, [pageIndex, pageSize, search, sorterInfo]);

  return (
    <>
      <ToastContainer />
      <BreadcrumbHeader title={"Equipment"} items={items} />
      <div className="content">
        <ModalAddNewEquiment
          setPageIndex={setPageIndex}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleGetListEquipments={handleGetListEquipments}
        />
        <div className="report-page setting-page p-4">
          <div className="daily-report">
            <div className="d-flex justify-content-between align-items-center">
              <div className="total-report">
                {totalRecord} {totalRecord > 1 ? "Equipments" : "Equipment"}
              </div>
              <div className="d-flex gap-2">
                <div className="form-input">
                  <input
                    placeholder="Search"
                    value={search}
                    onChange={handleInputSearch}
                    maxLength={100}
                  />
                  <div className="icon">
                    <SearchOutlined />
                  </div>
                </div>
                {userRole === Role.Admin && (
                  <Button
                    title={"Add"}
                    icon={<PlusOutlined />}
                    handleClick={showModal}
                  />
                )}
              </div>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TableEquipment
                setSorterInfo={setSorterInfo}
                setPageIndex={setPageIndex}
                listData={listData}
                handleGetListEquipments={handleGetListEquipments}
                pageSize={pageSize}
              />
            </div>
            <div className="text-end">
              <PaginationCommon
                totalRecord={totalRecord}
                pageSize={pageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
