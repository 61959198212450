import React from "react";
import { Table } from "@themesberg/react-bootstrap";
import { TableRow } from "./TableRow";

export default ({
  listWorkPlans,
  setListWorkPlans,
  setPageIndex,
  handleGetListWorkPlans,
}) => {
  return (
    <Table
      responsive
      className="tabel table-centered table-nowrap rounded mb-0 table-work-plan"
    >
      <thead className="thead-light">
        <tr>
          <th>
            <div>Location</div>
          </th>
          <th>
            <div>Work item</div>
          </th>
          <th>
            <div>Design volume</div>
          </th>
          <th>
            <div>Unit 1</div>
          </th>
          <th>
            <div>Production rate</div>
          </th>
          <th>
            <div>Unit 2</div>
          </th>
          <th>
            <div>Operating ratio</div>
          </th>
          <th>
            <div>Duration</div>
          </th>
          <th>
            <div>Production rate (Adjust)</div>
          </th>
          <th>
            <div>Unit 3</div>
          </th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {listWorkPlans.map((item, index) => (
          <TableRow
            key={`page-equipment-${item?.idWorkPlan}`}
            id={item?.idWorkPlan}
            {...item}
            index={index}
            setPageIndex={setPageIndex}
            handleGetListWorkPlans={handleGetListWorkPlans}
            listWorkPlans={listWorkPlans}
            setListWorkPlans={setListWorkPlans}
          />
        ))}
      </tbody>
    </Table>
  );
};
