import { InputNumber, Popconfirm, Tooltip } from "antd";
import React, { useState } from "react";
import { ReactComponent as IconDelete } from "../../assets/icons/icon-delete.svg";
import { apiDeleteWorkPlan } from "../../services/WorkPlan";
import { ToastFailed, ToastSuccess } from "../common/Toast";
import { formatNumberSimple } from "../../helps/Format";
import { useEditing } from "../../hook/EdittingContext";
import { formatString } from "../../utils/FormatString";
import { getSiteId } from "../../utils/auth";
import { useSelector } from "react-redux";

export const TableRow = (props) => {
  const {
    id,
    index,
    unit1,
    setPageIndex,
    handleGetListWorkPlans,
    listWorkPlans,
    setListWorkPlans,
  } = props;

  const { isEditing, setIsEditing } = useEditing();
  const siteSelected =
    useSelector((state) => state.site).selectedSite?.siteId ||
    JSON.parse(getSiteId())?.siteId;

  const handleDeleteWorkPlan = async (id, index) => {
    if (id && siteSelected) {
      try {
        const response = await apiDeleteWorkPlan(id, siteSelected);
        if (response?.header?.resultCode === "1000") {
          setPageIndex(1);
          handleGetListWorkPlans();
          ToastSuccess("Tne plan has been deleted successfully.");
        }
      } catch (error) {
        if (error.response) {
          if (
            error.response.data.header?.message?.includes(
              "exists report cannot be deleted"
            )
          ) {
            ToastFailed(
              "The plan already has a report. You need to delete the report before deleting the plan."
            );
          } else {
            ToastFailed("The plan has been deleted failed.");
          }
        } else {
          // Handle other unexpected errors
          console.log(error);
          ToastFailed("Some thing went wrong, please try again.");
        }
      }
    } else {
      let newListWorkPlans = [...listWorkPlans];
      newListWorkPlans.splice(index, 1);
      setListWorkPlans(newListWorkPlans);
    }
  };

  const onChange = (value, field) => {
    const updatedList = [...listWorkPlans];
    const currentItem = { ...updatedList[index] };

    // Nếu giá trị null hoặc không hợp lệ, gán giá trị mặc định là 0
    currentItem[field] =
      value === null || isNaN(value) ? 0 : parseFloat(value.toFixed(3));

    // Lấy các giá trị hiện tại của các trường liên quan
    const {
      designVolume = 0,
      productionRate = 0,
      operatingRatio = 0,
    } = currentItem;

    // Tính toán lại các trường phụ thuộc
    if (designVolume && productionRate && operatingRatio) {
      currentItem.duration = Math.ceil(
        designVolume / productionRate / operatingRatio
      );
    } else {
      currentItem.duration = 0;
    }

    if (designVolume && currentItem.duration) {
      currentItem.productionRateAdjust = parseFloat(
        (designVolume / currentItem.duration).toFixed(3)
      );
    } else {
      currentItem.productionRateAdjust = 0;
    }

    // Cập nhật giá trị vào danh sách
    updatedList[index] = currentItem;
    setListWorkPlans(updatedList);

    // Đánh dấu trạng thái đang chỉnh sửa
    setIsEditing(true);
  };

  const onBlur = (field) => {
    const updatedList = [...listWorkPlans];
    const currentItem = { ...updatedList[index] };

    // Gán giá trị mặc định là 0 nếu người dùng xóa hết giá trị
    if (currentItem[field] === null || isNaN(currentItem[field])) {
      currentItem[field] = 0;
    }

    // Tính toán lại các trường phụ thuộc
    const {
      designVolume = 0,
      productionRate = 0,
      operatingRatio = 0,
    } = currentItem;

    if (designVolume && productionRate && operatingRatio) {
      currentItem.duration = Math.ceil(
        designVolume / productionRate / operatingRatio
      );
    } else {
      currentItem.duration = 0;
    }

    if (designVolume && currentItem.duration) {
      currentItem.productionRateAdjust = parseFloat(
        (designVolume / currentItem.duration).toFixed(3)
      );
    } else {
      currentItem.productionRateAdjust = 0;
    }

    updatedList[index] = currentItem;
    setListWorkPlans(updatedList);
  };

  const handleKeyPress = (event) => {
    const inputElement = event.target;
    const charCode = event.which ? event.which : event.keyCode;
    const newChar = String.fromCharCode(charCode);
    const cursorPosition = inputElement.selectionStart;
    const inputValue = event.target.value;

    const updatedValue =
      inputValue == "0"
        ? newChar
        : inputValue.slice(0, cursorPosition) +
          newChar +
          inputValue.slice(cursorPosition);
    const validInput = updatedValue.replace(/[,]/g, ""); // Loại bỏ dấu phẩy và dấu chấm để tính số ký tự hợp lệ

    // Kiểm tra nếu tổng số ký tự hợp lệ đã nhập không vượt quá 14 ký tự
    if (validInput.length > 14 && charCode !== 8) {
      // 8 là mã của phím backspace
      event.preventDefault();
      return;
    }

    // Cho phép các phím từ 0 đến 9, phím điều khiển (backspace), dấu chấm
    if (
      (charCode >= 48 && charCode <= 57) || // Số từ 0-9
      charCode === 46 || // Dấu chấm
      charCode === 8 // Backspace
    ) {
      const [integerPart, decimalPart] = validInput.split(".");
      // Chỉ cho phép một dấu chấm
      if (charCode === 46 && inputValue.includes(".")) {
        event.preventDefault();
        return;
      }
      if (decimalPart && decimalPart.length > 3) {
        if (
          (integerPart && integerPart.length > 10) ||
          charCode !== 46 ||
          decimalPart.length > 3
        ) {
          event.preventDefault();
          return;
        }
      }
      if ((integerPart && integerPart.length > 10) || charCode !== 46) {
        if (
          (decimalPart && decimalPart.length > 3) ||
          integerPart.length > 10
        ) {
          event.preventDefault();
          return;
        }
      }
    } else {
      // Chặn các ký tự không phải số, dấu chấm
      event.preventDefault();
    }
  };

  const formatValue = (value) => {
    // Tách phần nguyên và phần thập phân
    const [integerPart, decimalPart] = value.split(".");

    // Định dạng phần nguyên với dấu phẩy
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    // Ghép lại phần nguyên đã format với phần thập phân nguyên vẹn
    if (decimalPart) {
      return `${formattedIntegerPart}.${decimalPart}`;
    }

    return formattedIntegerPart;
  };
  return (
    <tr>
      <td>
        {listWorkPlans[index].location.replaceAll("/", "").length > 50 ? (
          <Tooltip
            placement="topRight"
            title={listWorkPlans[index].location.replaceAll("/", "")}
          >
            <div className="item-show_data">
              {formatString(
                listWorkPlans[index].location.replaceAll("/", ""),
                25,
                50
              ).map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          </Tooltip>
        ) : (
          <div className="item-show_data">
            <div className="item-show_data">
              {formatString(
                listWorkPlans[index].location.replaceAll("/", ""),
                25,
                50
              ).map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          </div>
        )}
      </td>
      <td>{listWorkPlans[index].workItemName}</td>
      <td>
        <InputNumber
          style={{ width: "127px" }}
          min={0}
          max={9999999999.999}
          value={listWorkPlans[index]["designVolume"]}
          parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
          onChange={(e) => onChange(e, "designVolume")}
          onBlur={() => {
            onBlur("designVolume");
          }}
          formatter={formatValue}
          onKeyPress={handleKeyPress}
          onCopy={(e) => e.preventDefault()} // Chặn sao chép
          onPaste={(e) => e.preventDefault()} // Chặn dán
          onCut={(e) => e.preventDefault()}
        />
      </td>
      <td>{unit1}</td>
      <td>
        <InputNumber
          style={{ width: "127px" }}
          min={0}
          max={9999999999.999}
          value={listWorkPlans[index]["productionRate"]}
          parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
          onChange={(e) => onChange(e, "productionRate")}
          onBlur={() => {
            onBlur("productionRate");
          }}
          formatter={formatValue}
          onKeyPress={handleKeyPress}
          onCopy={(e) => e.preventDefault()} // Chặn sao chép
          onPaste={(e) => e.preventDefault()} // Chặn dán
          onCut={(e) => e.preventDefault()}
        />
      </td>
      <td>{listWorkPlans[index]["unit2"]}</td>
      <td>
        <InputNumber
          style={{ width: "127px" }}
          min={0}
          max={9999999999.999}
          value={listWorkPlans[index]["operatingRatio"]}
          parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
          onChange={(e) => onChange(e, "operatingRatio")}
          onBlur={() => {
            onBlur("operatingRatio");
          }}
          formatter={formatValue}
          onKeyPress={handleKeyPress}
          onCopy={(e) => e.preventDefault()} // Chặn sao chép
          onPaste={(e) => e.preventDefault()} // Chặn dán
          onCut={(e) => e.preventDefault()}
        />
      </td>
      <td>{formatNumberSimple(listWorkPlans[index]["duration"])}</td>
      <td>
        {formatNumberSimple(listWorkPlans[index]["productionRateAdjust"])}
      </td>
      <td>{listWorkPlans[index]["unit3"]}</td>
      <td>
        <Popconfirm
          title="Are you sure delete this work plan?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleDeleteWorkPlan(id, index)}
        >
          <div className="delete">
            <IconDelete />
          </div>
        </Popconfirm>
      </td>
    </tr>
  );
};
