import React, { forwardRef, useState } from "react";
import { Table } from "@themesberg/react-bootstrap";
import { InputNumber, Select } from "antd";
import { ReactComponent as IconDelete } from "../../../assets/icons/icon-delete.svg";
import { useEditing } from "../../../hook/EdittingContext";

export const TableManpower = forwardRef(
  (
    {
      pageManpowers,
      setPageManpowers,
      lstManpowerCbb,
      isValidManpower,
      setIsValidManpower,
    },
    ref
  ) => {
    const { isEditing, setIsEditing } = useEditing();

    const handleSaveManpower = (value, index) => {
      const newPageManpowers = [...pageManpowers];
      newPageManpowers[index].manpower = value;
      setPageManpowers(newPageManpowers);
      setIsValidManpower(true);
      setIsEditing(true);
    };

    const handleOnChange = (value, index) => {
      const newPageManpowers = [...pageManpowers];
      newPageManpowers[index].amount = value;
      setPageManpowers(newPageManpowers);
      setIsEditing(true);
    };

    const handleDelete = (index) => {
      const newPageManpowers = [...pageManpowers];
      newPageManpowers.splice(index, 1);
      setPageManpowers(newPageManpowers);
      setIsEditing(true);
    };

    const handleKeyPress = (event) => {
      const inputElement = event.target;
      const charCode = event.which ? event.which : event.keyCode;
      const newChar = String.fromCharCode(charCode);
      const cursorPosition = inputElement.selectionStart;
      const inputValue = event.target.value;

      const updatedValue =
        inputValue == "0"
          ? newChar
          : inputValue.slice(0, cursorPosition) +
            newChar +
            inputValue.slice(cursorPosition);
      const validInput = updatedValue.replace(/[,]/g, "");

      if (parseFloat(validInput) > 1000 && charCode !== 8) {
        event.preventDefault();
        return;
      }

      if (
        (charCode >= 48 && charCode <= 57) || // Số từ 0-9
        charCode === 46 ||
        charCode === 8
      ) {
        const [integerPart, decimalPart] = validInput.split(".");

        if (charCode === 46 && inputValue.includes(".")) {
          event.preventDefault();
          return;
        }

        if (decimalPart && decimalPart.length > 3) {
          event.preventDefault();
          return;
        }

        if (integerPart && parseInt(integerPart) > 1000) {
          event.preventDefault();
          return;
        }
      } else {
        event.preventDefault();
      }
    };

    const formatValue = (value) => {
      const formattedIntegerPart = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedIntegerPart;
    };

    const getOptionsForIndex = (index) => {
      const selectedManpowers = pageManpowers
        .filter((_, i) => i !== index)
        .map((item) => item.manpower);

      return lstManpowerCbb.filter(
        (option) => !selectedManpowers.includes(option.value)
      );
    };

    return (
      <Table
        responsive
        className="tabel table-centered table-nowrap rounded mb-0"
      >
        <thead className="thead-light">
          <tr>
            <th style={{ width: "50px" }}>#</th>
            <th>Manpower</th>
            <th style={{ width: "144px" }}>Amount</th>
            <th style={{ width: "47px" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {pageManpowers.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>
                <Select
                  className="w-100"
                  value={item.manpower}
                  onChange={(value) => handleSaveManpower(value, index)}
                  showSearch
                  options={getOptionsForIndex(index)}
                  placeholder="Select Manpower"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
                {index === 0 && !isValidManpower && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {"Manpower is required field"}
                  </div>
                )}
              </td>
              <td>
                <InputNumber
                  value={item.amount}
                  onChange={(value) => handleOnChange(value, index)}
                  min={1}
                  max={1000}
                  formatter={formatValue}
                  onKeyPress={handleKeyPress}
                  onCopy={(e) => e.preventDefault()} // Chặn sao chép
                  onPaste={(e) => e.preventDefault()} // Chặn dán
                  onCut={(e) => e.preventDefault()}
                />
              </td>
              <td>
                <div className="delete" onClick={() => handleDelete(index)}>
                  <IconDelete />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
);
