import client from "../api/axiosClient";

const apiCreateEquipment = (data) => {
  return client.post("/api/equipment/create", data);
};

const apiEditEquipment = (data) => {
  return client.put("/api/equipment/update", data);
};

const apiDeleteEquipment = (id) => {
  return client.delete(`/api/equipment/delete/${id}`);
};

const apiGetListEquipments = ({
  pageIndex,
  pageSize,
  textSearch,
  isGetDataWithNullSiteId,
  siteId,
  field,
  order,
}) => {
  return client.get(
    `/api/equipment/list?currentPage=${pageIndex}&DisplayItem=${pageSize}&textSearch=${textSearch}&IsGetDataWithNullSiteId=${isGetDataWithNullSiteId}&SiteId=${siteId}&Field=${field}&Order=${order}`
  );
};

const apiDetailEquipment = (id) => {
  return client.get(`/api/equipment/detail/${id}`);
};

const apiDetailEquipmentBySite = (siteId) => {
  return client.get(`/api/equipment/detailBySite/${siteId}`);
};

export {
  apiCreateEquipment,
  apiEditEquipment,
  apiDeleteEquipment,
  apiGetListEquipments,
  apiDetailEquipment,
  apiDetailEquipmentBySite,
};
